<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><a href="#"> <i class="fas fa-building"></i> </a></li>
          <li class="breadcrumb-item"> <i class="fas fa-chevron-right"></i> <a href="#">{{ translateAktif == 'id' ? 'Tentang Kami' : 'About'}}</a></li>
          <li class="breadcrumb-item active"> <i class="fas fa-chevron-right"></i> <span>{{ translateAktif == 'id' ? 'Tentang Kami' : 'About Us'}}</span></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->

    <section class="space-ptb">
    <div class="container">
        <div class="row">
        <div class="col-lg-6">
            <div class="embed-responsive embed-responsive-16by9">
                <!-- <video width="640" height="360" controls>
                    <source :src="getImage('assets/icon/short.mp4')" type="video/mp4">
                </video> -->
                <iframe width="560" height="315" src="https://www.youtube.com/embed/-Ga-Q4kvQzs?si=Ql6iKvNXSsAzhcaD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
             </div>
        </div>
        <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="section-title mb-3">
            <h2>{{ translateAktif == 'id' ? 'Tentang Kami' : 'About Us'}}</h2>
            </div>
            <p v-if="translateAktif == 'id'">Selamat datang di Hasina, Hasina adalah usaha kami di bidang batik dan konveksi, 
                kami sudah berdiri sejak tahun 2000, dan brand Hasina juga sudah resmi terdaftar 
                di Indonesia. Kami memproduksi beberapa bahan kain batik, seperti batik tulis, 
                batik cap, batik printing dan digital printing, batik costum. Kami juga 
                menyediakan jasa pembuatan seragam kantor, seragam sekolah SD, SMP, SMA bahkan TK, 
                Jas, Jaket, Sweater, Kemeja, kaos oblong, kaos polo, hingga dapat produksi tas dan 
                menyediakan alat tulis kantor</p>
            <p v-if="translateAktif == 'en'">Welcome to Hasina, Hasina is our business in the field of batik and convection, we have 
                been established since 2000, and the Hasina brand has also been officially registered 
                in Indonesia. We produce several batik fabric materials, such as written batik, stamped batik, 
                printed and digital printed batik, custom batik. We also provide services for making office 
                uniforms, elementary school, middle school, high school and even kindergarten uniforms, jackets,
                 jackets, sweaters, shirts, T-shirts, polo shirts, we can even produce bags and provide office 
                 stationery.</p>
            <!-- <p>Batik culture has become our routine for generations, thanks to our ancerstors.</p>
            <p>However, the name Haisna was born in 2000, when it was founded by Mrs. Fanikmah and Mr. Agung
                in Pekalongan City, Central Java, Indonesia.
            </p>
            <p>At first, we only produced batik, but over time, we expanded into the convection sector, namely 
                in 2011.
            </p> -->
        </div>
        </div>
        <!-- <div class="row justify-content-center text-sm-center">
        <div class="col-sm-3">
            <div class="counter counter-02 mt-4 mt-sm-5">
            <div class="counter-content">
                <span class="timer mb-1" data-to="4561" data-speed="10000">1000</span>
                <label class="mb-0">Product</label>
            </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="counter counter-02 mt-4 mt-sm-5">
            <div class="counter-content">
                <span class="timer mb-1" data-to="3256" data-speed="10000">1234</span>
                <label class="mb-0">Product</label>
            </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="counter counter-02 mt-4 mt-sm-5">
            <div class="counter-content">
                <span class="timer mb-1" data-to="2564" data-speed="10000">5566</span>
                <label class="mb-0">Product</label>
            </div>
            </div>
        </div>
        </div> -->
    </div>
    </section>

    </div>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import { mapState } from 'vuex'

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Tentang Kami',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        mounted() {
            this.getLocalStorage();
        },
        computed: mapState(['school_info']),
        data() {
            return {
                translateAktif: this.$root.$translateAktif,
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                this.$router.push('/admin/dashboard');
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
            }
        }
    }
</script>